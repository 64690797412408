<template>
  <div class="box">
    <img src="https://i.gtimg.cn/club/item/face/img/2/16022_100.gif">
    <div class="t">
      <div class="title">晚上好</div>
      <div class="stitle">重磅更新：主题配置功能背景配置，横向布局重构</div>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN
    };
  },
  created() {
    this.getHomeInfo();
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    getHomeInfo() {
      var that = this

    }
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}
.box img {
  border-radius: 50%;
  float: left;
  margin-right: 20px;
}
.box .t {
  float: left;
  font-size: 16px;
  font-weight: 500;
}
.box .stitle {
  font-size: 12px;
  font-weight: 400;
  margin-top: 6px;
}
</style>
